<template>
  <div class="box-body">
    <div class="panel panel-default">
      <div v-if="([1, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carResponsibleId === $store.getters['auth/authUser'].id) && !isDeleted"
           class="form-group col-12">
        <transition name="fade">
          <vue-dropzone v-if="onProgressLoadFile === false"
                        id="dropzone-photos"
                        ref="car_photos"
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        v-on:vdropzone-file-added="sendPhoto"
                        v-on:vdropzone-success="getFiles"
                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
          </vue-dropzone>
          <loading-processing v-else :size="60"></loading-processing>
        </transition>
      </div>

      <transition name="fade">
        <div class="row" v-if="onProgress === false">
          <div class="col-lg-3 col-md-6 col-sm-12" v-for="item in car_photos">
            <div class="image-box">
              <img :src="item.storage_app_url + item.img_url"
                   class="img-fluid p-3" :alt="carTitle">
              <button v-if="([1, 2, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carCreator.id === $store.getters['auth/authUser'].id) && !isDeleted"
                      @click="removeFile(carId, item.id)"
                      class="btn-remove" title="Удалить изображение" type="button">
                <i class="fa fa-trash"></i>
              </button>
              <button v-if="([1, 2, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carCreator.id === $store.getters['auth/authUser'].id) && !isDeleted"
                      @click="rotate90(carId, item.id)"
                      class="btn-rotate" title="Перевернуть изображение на 180 градусов" type="button">
                <i class="fa fa-camera-rotate"></i>
              </button>
            </div>

            <input v-if="([1, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carCreator.id === $store.getters['auth/authUser'].id) && !isDeleted"
                   class="form-control font-small mb-2"
                   type="number"
                   :value="item.position"
                   style="margin: 0 15px; width: 75%;"
                   placeholder="Позиция"
                   @change="setPosition(carId, item.id, $event.target.value)">
          </div>
          <div v-if="car_photos.length === 0" class="text-center text-muted mt-5 mb-5">
            Нет элементов для отображения
          </div>
        </div>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import dropzone_config from './../../../assets/data/dropzone-config.json';

export default {
  name: 'car-photos',

  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
    carCreator: {
      type: [Array, Object],
      required: true,
    },
    carId: {
      type: [String, Number],
      required: true,
    },
    carTitle: {
      type: String,
      required: true,
    },
    carResponsibleId: {
      type: [String, Number],
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    car_photos: [],

    dropzoneOptions: dropzone_config,

    onProgress: true,
    onProgressLoadFile: false,
  }),

  async created() {
    try {
      this.dropzoneOptions.url = '/car/' + this.carId + '/photos/load';
      this.dropzoneOptions.thumbnailWidth = 270;
      // this.dropzoneOptions.capture = 'camera';

      await this.getFiles();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getFiles() {
      try {
        const car_photos_response = await API.apiClient.get('/car/' + this.carId + '/photos', {
          params: {
            sorting: 'asc',
            sort_column: 'position',
          }
        });

        this.car_photos = car_photos_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async sendPhoto(file) {
      try {
        this.onProgressLoadFile = true;
        this.$refs.car_photos.disable();

        const form_data = new FormData();
        form_data.append('car_id', this.carId);
        form_data.append('file', file);

        await API.apiClient.post(this.dropzoneOptions.url, form_data).then(() => {
          this.onProgressLoadFile = false;
          this.getFiles();
          showSuccess();
        });

        this.$refs.car_photos.removeFile(file);
      } catch (error) {
        this.onProgressLoadFile = false;
        errorHandler(error);
      }

      this.$refs.car_photos.enable();
    },
    setPosition(car_id, file_id, value) {
      API.apiClient.post('/car/' + this.carId + '/photos/position', {
        file_id: file_id,
        position: parseInt(value),
      }).then(() => {
        this.getFiles();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },
    rotate90(car_id, file_id) {
      API.apiClient.post('/car/' + this.carId + '/photos/rotate90', {
        file_id: file_id,
      }).then(() => {
        window.location.reload();
      }).catch((error) => {
        errorHandler(error);
      });
    },
    removeFile(car_id, file_id) {
      this.onProgress = true;

      API.apiClient.delete('/car/' + this.carId + '/photos', {
        data: {
          file_id: file_id,
        }
      }).then(() => {
        this.getFiles();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
}
</script>

<style scoped>
.image-box {
  position: relative;
}

.image-box img {
  width: 100%;
  height: auto;
}

.image-box .btn-remove {
  min-block-size: auto;
  position: absolute;
  top: 20%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #535550;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.65;
}

.image-box .btn-rotate {
  min-block-size: auto;
  position: absolute;
  top: 75%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #535550;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.65;
}

.image-box .btn:hover {
  background-color: black;
}
</style>
